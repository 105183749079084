<!-- 开始答题选项前弹窗 -->
<template>
    <div class="popups_app">
        <div class="title">
            <h2>{{ $parent.measureInfo.measure_title }}调查问卷</h2>
        </div>

        <el-form ref="form" :rules="rules" :model="form" label-width="120px" status-icon>
            <el-form-item label="怀孕周数：" prop="pregnancyPeriod">
                <el-input type="text" oninput="value=value.replace(/[^\d]/g,'')" v-model="form.pregnancyPeriod"
                    placeholder="周数"></el-input>
            </el-form-item>
            <div>
                <el-form-item label="预产期　：" prop="dueDate">
                    <el-date-picker v-model="form.dueDate" type="date" value-format="yyyy-MM-dd" placeholder="选择预产期"
                        :picker-options="pickerOptions">
                    </el-date-picker>
                </el-form-item>

            </div>
            <div>
                <el-form-item label="手机号码：" prop="phone">
                    <el-input type="text" v-model="form.phone" oninput="value=value.replace(/[^\d]/g,'')"
                        placeholder="手机号码">
                    </el-input>
                </el-form-item>

            </div>
            <div>
                <el-form-item label="电话(家庭)：" prop="homeTel">
                    <el-input type="text" v-model="form.homeTel" oninput="value=value.replace(/[^\d]/g,'')">
                    </el-input>
                </el-form-item>

            </div>
            <div>
                <el-form-item label="电话(工作)：" prop="jobTel">
                    <el-input type="text" v-model="form.jobTel" oninput="value=value.replace(/[^\d]/g,'')">
                    </el-input>
                </el-form-item>

            </div>
            <el-row type="flex" justify="center" style="margin-top: 30px">
                <el-button type="primary" plain @click="submitForm('form')">填写完成</el-button>
            </el-row>
        </el-form>
    </div>
</template>
<script>
export default {
    data() {
        return {
            pickerOptions: {
                //设置禁用状态，参数为当前日期
                disabledDate(time) {
                    // return time.getTime() < Date.now(); //当天不可选
                    return time.getTime() < new Date().getTime() - 86400000; //  - 86400000是否包括当天
                },
            },
            rules: {
                phone: [
                    { pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/, min: 11, max: 11, message: "格式不正确", trigger: "blur" }
                ],
                homeTel: [
                    { pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/, min: 11, max: 11, message: "格式不正确", trigger: "blur" }
                ],
                jobTel: [
                    { pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/, min: 11, max: 11, message: "格式不正确", trigger: "blur" }
                ],
                pregnancyPeriod: [
                    { pattern: /^\+?[1-9]\d*$/, message: '请输入正确的怀孕周数', trigger: "change" },
                    { min: 1, max: 2, message: "怀孕周数超出正常范围", trigger: "change" }
                ]
            },
            form: {
                pregnancyPeriod: '',// 怀孕周期
                dueDate: "",// 预产期
                phone: "", // 手机
                homeTel: "", // 电话 (家)
                jobTel: "", // 电话 (工作) 
            }
        };
    },
    methods: {
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.$parent.popups = true
                    this.$parent.popupsParam = this.form
                    for (var i in this.form) {
                        if (!this.form[i] === '') {
                            this.$parent.popupsParam[i] = this.form[i]
                        }
                    }
                } else {
                    return false;
                }
            });
        },
    }
}
</script>
<style>
.popups_app {
    max-width: 1200px;
    min-width: 320px;
    margin: 50px auto;
}

.title {
    text-align: center;
    margin-bottom: 2rem;
    padding-top: 2rem;
}

.popups_app form {
    margin: auto;
    width: 340px;
}
</style>