
import request from '@/utils/request'

//答题提交接口
export function submit(data) {
  return request({
    url: '/results_calculated',
    method: 'post',
    data
  })
}


// 获取答题列表页面
export function loadAnswerList() {
  return request({
    url: '/getusermea',
    method: 'get',
  })
}


// 获取用户信息
export function getuserinfo() {
  return request({
    url: '/getuserinfo',
    method: 'get',
  })
}


// 切换回管理员
export function switchoverUser(data) {
  return request({
    url: '/switchover_user',
    method: 'get',
    params: data
  })
}


// 二维码扫码获取题目
export function getQrmest(data) {
  return request({
    url: '/distributionusermea',
    method: 'get',
    params: data,
  })
}
// 二维码扫码获取题目
export function getMeasureCode(data) {
  return request({
    url: '/get_measure_code',
    method: 'get',
    params: data,
  })
}


// 问卷列表
export function getQueuser(data) {
  return request({
    url: '/getqueuser',
    method: 'get',
    params: data,
  })
}

// 获取单个问卷题目
export function userGetQuesubject(data) {
  return request({
    url: '/user_set_quesubject',
    method: 'get',
    params: data,
  })
}

// 问卷提交
export function putanswer(data) {
  return request({
    url: '/putanswer',
    method: 'post',
    data
  })
}

// 晤谈列表获取
export function getUserInterview(data) {
  return request({
    url: '/get_user_interview',
    method: 'get',
    params: data,
  })
}


// 晤谈内容获取
export function answerInterview(data) {
  return request({
    url: '/answer_interview',
    method: 'get',
    params: data,
  })
}


// 提交晤谈
export function sbmInterview(data) {
  return request({
    url: '/sbm_interview',
    method: 'post',
    data,
  })
}

// -------------游戏音乐-----------------
export function getUserGame(data) {
  return request({
    url: '/user_game',
    method: 'get',
    params: data,
  })
}

