<template>
  <div class="tc-answer-content" v-loading="loading" element-loading-text="加载中"
    element-loading-spinner="el-icon-loading" element-loading-background="rgba(183,208,208,0.8)">
    <answer-popups style="z-index: 999;" v-if="!popups"></answer-popups>
    <transition name="el-zoom-in-top" v-if="popups">
      <div class="answer-content-layout" v-if="brief">
        <div class="title">
          <p>{{ measureInfo.measure_title }}</p>
          <el-progress :percentage="Progress"></el-progress>
        </div>
        <div class="answer-main" v-if="questionsList.length > 0">
          <!-- 题目标题 -->
          <div class="main-title"  v-if="setInput(questionsList[index].question)">
          		<span>{{setInputQuest(questionsList[index].question)[0]}}</span>
          		<el-input v-show="picked2List.indexOf(index) != -1" :readonly="inputValList.indexOf(index) == -1" style="width:30%;margin-left:20px" v-model="picked2" @blur="onBlur(picked2,2)"></el-input>
          		<el-input v-show="picked3List.indexOf(index) != -1" :readonly="inputValList.indexOf(index) == -1" style="width:30%;margin-left:20px" v-model="picked3" @blur="onBlur(picked3,3)"></el-input>
          		<el-input v-show="picked4List.indexOf(index) != -1" :readonly="inputValList.indexOf(index) == -1" style="width:30%;margin-left:20px" v-model="picked4" @blur="onBlur(picked4,4)"></el-input>
          		<span>{{setInputQuest(questionsList[index].question)[1]}}</span>
          </div>
		      <div class="main-title" v-html="questionsList[index].question" v-else></div>
          <!-- 题目选项 -->
          <div class="centen">
            <table @change="radio">
              <tr v-for="(item, key) in questionsList[index].options" :key="key">
                
                <td v-if="LessList.indexOf(measureInfo.measure_title) != -1 && inputQuesList.indexOf(index+1) != -1">
                  <div> <el-input v-model="inputVal" type="number"></el-input></div>
                </td>
                <td v-else>
                  <input type="radio" :id="key" :value="key" v-model="picked" />
                  <label :for="key">
                    <i class="fa fa-square-o" v-if="item"></i>
                    <span v-html="item"></span>
                  </label>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="footer">
          <div class="btn">
            <el-button @click="last">上一题</el-button>
          </div>
          <div class="sum">
            <span>
              共
              <span class="sum_info" v-if="measureInfo.questions">{{
                  measureInfo.questions.length
              }}</span>&nbsp;题&nbsp;
            </span>
            <br />
            <span>
              当前第
              <!-- 2022年11月1日 唐加宇 处理跳题的题号显示问题，跳题逻辑没看到，只能调整用户看到的那部分，通过题目名称拿到题号，如果没有拿到使用之前的方式 -->
              <span class="sum_info" v-if="questionsList[index].question.match(/^\d*/)[0]">{{
                  questionsList[index].question.match(/^\d*/)[0]
              }}</span>
              <span class="sum_info" v-else>{{ index + 1 }}</span>
              &nbsp;题
            </span>
          </div>
          <div class="btn">
            <el-button @click="next" v-if="
              (answer.length < questionsList.length ||
              index < questionsList.length - 1) && !isEnd
            ">下一题</el-button>
            <!--  -->
            <el-button @click="submit()" v-if="
              answer.length >= questionsList.length &&
              index >= questionsList.length - 1 || isEnd
            " :disabled="isSubmit">提 交</el-button>
          </div>
        </div>
        <!-- ------------------测试模态框 ------------------------------------>
        <el-dialog title="测试专用" :visible.sync="testBox" width="50%" center>
          <el-form ref="testform" :model="testform" label-width="80px">
            <el-form-item label="全选">
              <el-radio-group v-model="testform.resource">
                <el-radio label="A">全选 A</el-radio>
                <el-radio label="B">全选 B</el-radio>
                <el-radio label="C">全选 C</el-radio>
                <el-radio label="D">全选 D</el-radio>
                <el-radio label="E">全选 E</el-radio>
                <el-radio label="F">全选 F</el-radio>
                <el-radio label="G">全选 G</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="testBox = false">取 消</el-button>
            <el-button type="primary" @click="setTest">提 交</el-button>
          </span>
        </el-dialog>
        <!-- ----------------------------------------------------------------->
      </div>
    </transition>
    <!-- 量表介绍 -->
    <div class="answer-content-layout" v-if="!brief">
      <div class="title">
        <p>{{ measureInfo.measure_title }}</p>
      </div>
      <div class="describe">
        <p v-html="measureInfo.describe"></p>
      </div>
      <div class="msg">
        <p v-html="measureInfo.msg"></p>
      </div>
      <el-row type="flex" justify="center" style="margin-top: 30px">
        <el-button type="primary" plain @click="hideBox">开始答题</el-button>
      </el-row>
    </div>
    <!-- 偷偷加载 -->
    <div class="toto" v-show="false">
      <div v-for="(r, k) in questionsList" :key="k">
        <div v-html="r.question"></div>
        <div v-for="(r2, k2) in r.options" :key="k2">
          <div v-html="r2"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import answerPopups from "./answer_popups/index.vue"
// import { nextTick } from "q";
import { submit } from "@/api/answer.js";
import { saveInfo } from "@/api/comm.js";
import { userInfo } from "os";
export default {
  inject: ["reload"],
  data() {
    return {
      inputVal: '',
      inputsList: [],
      inputQuesList: [5,10,15,20,25,30,43,76,113],

      answerarr: [],
      flag: false,
      sixFlag: false,
      eightFalg: false,
      isSubmit: false, //提交按钮
      loading: true,
      testform: { resource: "" },
      testBox: false,
      ischeck: "",
      resource: "",
      picked: "",
	    picked2: "", // TESS 100-102
      picked3: "", // TESS 103-102
      picked4: "", // TESS 100-102
      measureInfo: "",
      questionsList: [],
      oldquestionsList: [],
      oldquestionsList1: [],
      oldquestionsList2: [],
      oldquestionsList3: [],
      oldquestionsListSix: [],
      index: 0,
      answer: [],
      type: 0,
      measureId: "",
      uid: "",
      Progress: 0,
      brief: false,
      continueMes: false, // 连续填写问卷开关
      recordTiao: [],
      isEnd: false,
      popups: true,// 开始答题选项前弹窗开关
      popupsParam: {},// 开始答题前的自定义参数数据
      inputValList: [99,102,105],
      picked2List: [99,100,101],
      picked3List: [102,103,104],
      picked4List: [105,106,107],

      LessList: ['紧张性生活事件评定量表']
    };
  },
  components: {
    answerPopups
  },
  created() {
    this.loadanswer();
  },
  methods: {
    // ----------------------测试专用------------------------------
	onBlur(val,type){
    switch(type) {
      case 2:
        localStorage.setItem('input2', val);
        break;
      case 3:
        localStorage.setItem('input3', val);
        break;
      case 4:
        localStorage.setItem('input4', val);
        break;
    }
		
	},
	setInput(row){
	  return row.includes('由评定者填写');
	},
  clearInput() {  // 清空题目的input框数据！
    this.picked2 = '';
  },
	setInputQuest(row){
		const arr = row.split('______');
		return arr;
	},
    showtestBox() {
      this.testBox = true;
    },
    hideBox() {
      this.brief = true;
      // 2022年10月31日 产前风险问卷（ANRQ）前新增弹窗
      // console.log(this.measureInfo);
      if (this.measureInfo.measure_title == '产前风险问卷（ANRQ）') {
        this.popups = false
      }
    },
    setTest() {
      // 数据处理
      this.questionsList.forEach((v, k) => {
        let obj = { id: v.id, answer: this.testform.resource };
        this.answer.push(obj);
      });
      this.submit();
    },
    // ----------------------------------------------------
    // 单选监控
    radio(val) {
      // 
      if (this.LessList.indexOf(this.measureInfo.measure_title) != -1 && this.inputQuesList.indexOf(this.index + 1) != -1){
        return;
      };
      // //console.log(val.target.parentNode.parentNode);
      // // 字体图标的变换
      var p = document.querySelectorAll(".fa");
      // // 1-还原所有
      p.forEach((v) => {
        v.classList.remove("fa-check-square");
        v.classList.add("fa-square-o");
      });
      // // 2-判断类名，设置类名
      var className = val.target.parentNode.children[1].children[0].getAttribute(
        "class"
      );
      if (className == "fa fa-square-o") {
        val.target.parentNode.children[1].children[0].classList.remove(
          "fa-square-o"
        );
        val.target.parentNode.children[1].children[0].classList.add(
          "fa-check-square"
        );
      }
      // // tr 背景的切换
      // // 1-清除所有 tr背景
      var tr = document.querySelectorAll("tr");
      tr.forEach((v) => {
        v.classList.remove("active");
      });
      // // 2-设置当前tr背景
      val.target.parentNode.parentNode.classList.add("active");
      setTimeout(() => {
        this.next();
      }, 250);
    },
    async loadanswer() {
      let id = this.$route.query.id;
      this.measureId = this.$route.query.id;
      let r = await axios.get(`/get_measure_by_id?id=${id}`);
      // //console.log("r", r.data);
      if (r.data.code == 400400) {
        this.$message({
          message: r.data.msg,
          type: "warning",
          center: true,
          showClose: true,
          duration: 1500,
        });
        this.$router.go(-1); //返回上一层
        return;
      }
      this.measureInfo = r.data.data;
      //  题目数据
      // r.data.data.questions.forEach((v,k)=>{
      //   var thishtml = [];
      //   v.options.forEach((vs,ks)=>{
      //     //console.log(vs);
      //       vs = vs.replace("<imgdiv>", "<img src='/ruiwen/ruiwen/");
      //       vs = vs.replace("</imgdiv>", ".jpg'>");
      //       thishtml[ks] = vs;
      //   })
      //   v = thishtml;
      //   console.log(thishtml);
      // })

      this.questionsList = r.data.data.questions;
      // 都没删除
      this.oldquestionsList = r.data.data.questions;
      // 前面删了，后面没删除
      r.data.data.questions.filter((item, index) => {
        let obj = {
          id: item.id,
          answer: ""
        }
        this.oldquestionsList1.push(obj)
      })


      // 前面有删除，后面不删除
      // console.log(this.questionsList);
      this.loading = false;
      // //console.log(this.measureInfo);
      this.type = r.data.show_result;
      // 获取缓存,续题
      this.uid = localStorage.getItem("user_id");
      if (
        JSON.parse(
          localStorage.getItem(`measureId${this.uid}-${this.measureId}`)
        )
      ) {
        this.brief = true;
        this.$confirm("是否从上一次答题处开始?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            // 加载LES缓存
            if (localStorage.getItem("inputsList")) {
               this.inputsList = JSON.parse(localStorage.getItem("inputsList"));
            }
			      this.picked2 = localStorage.getItem('input2') || '';
			      this.picked3 = localStorage.getItem('input3') || '';
			      this.picked4 = localStorage.getItem('input4') || '';
            this.answer = JSON.parse(
              localStorage.getItem(`measureId${this.uid}-${this.measureId}`)
            );
            this.recordTiao =
              JSON.parse(
                localStorage.getItem(`recordTiao${this.uid}-${this.measureId}`)
              ) ?? [];
            if (this.answer.length >= this.questionsList.length) {
              this.index = this.answer.length - 1;
            } else {
              this.index = this.answer.length;
            }
            // 计算进度条
            this.Progress = Math.ceil(
              (this.answer.length / this.questionsList.length) * 100
            );
            // 如果是最后一题,则渲染并 提示提交
            if (this.answer.length == this.questionsList.length) {
              //模拟已选择
              var arr = [
                "A",
                "B",
                "C",
                "D",
                "E",
                "F",
                "G",
                "H",
                "I",
                "J",
                "K",
                "L",
                "M",
                "N",
                "O",
                "P",
                "Q",
                "R",
                "S",
                "T",
                "U",
                "V",
                "W",
                "X",
                "Y",
                "Z",
              ];
              var answers = this.answer[this.answer.length - 1].answer;
              var index = arr.indexOf(answers);
              this.picked = index;
              this.$nextTick(() => {
                document.querySelectorAll("tr")[index].classList.add("active");
                document
                  .querySelectorAll(".fa")
                [index].classList.add("fa-check-square");
              });
            }
          })
          .catch(() => {
            // 删除LES缓存
            if (localStorage.getItem("inputsList")) {
               localStorage.removeItem("inputsList");
            }
            localStorage.removeItem(`measureId${this.uid}-${this.measureId}`);
            localStorage.removeItem(`recordTiao${this.uid}-${this.measureId}`);
            this.$message({
              type: "info",
              showClose: true,
              duration: 1500,
              message: "已重置",
            });
            this.brief = false;
          });
      }
    },
    // 
    changeTheTopic(index, falg) {
      // 获取题目的真实题号 下面BUG多，不敢动了
      var keyInfo = function (item) {
        return item.question.match(/^\d*/)[0];
      }
      // 第3题
      if (index == 3 && falg) {
        //删除掉四、五、六题
        let arr = this.questionsList.filter((item, index) => {
          if (keyInfo(item) != 4 && keyInfo(item) != 5 && keyInfo(item) != 6) {
            return item
          }
        })
        this.questionsList = arr
        // 第五题没有就第六题没有
      } else {
        // 返回修改后又进入
        this.questionsList = [...this.oldquestionsList]
      }
      // 第5题
      if (index == 5) {
        // console.log("第五题存在时候的选择",this.answer[4].answer);
        if (this.answer[4].answer == 'B') {
          // 选否删除掉第6题；
          // 第二种情况只有第6题没有了
          this.sixFlag = true;
          this.questionsList = this.oldquestionsList.filter((item, index) => {
            if (index != 5) {
              return item
            }
          })
        } else {
          // 此时是1,2,3,4，5题目都在
          this.sixFlag = false;
          this.questionsList = [...this.oldquestionsList]
        }
      }

      // 第8题,情况1删除4,5,6
      if (index == 81) {
        // 第八题管第九题
        if (this.answer[4].answer == 'B') {
          this.questionsList = this.oldquestionsList.filter((item, index) => {
            if (index != 8 && index != 3 && index != 4 && index != 5) {
              return item
            }
          })
          this.nineFalg = true;
        } else {
          // 此时是1,2,3,4，5题目都在
          this.nineFalg = false;
          this.questionsList = this.questionsList = this.oldquestionsList.filter((item, index) => {
            if (index != 3 && index != 4 && index != 5) {
              return item
            }
          })
        }
      }
      // 第8题,情况2此时存在4、5、6
      if (index == 82 && !this.sixFlag) {
        // 第八题管第九题
        if (this.answer[7].answer == 'B') {
          this.questionsList = [...this.oldquestionsList].filter((item, index) => {
            if (index != 8) {
              return item
            }
          })
          this.nineFalg = true;
        } else {
          // 此时是1,2,3,4，5题目都在
          this.nineFalg = false;
          this.questionsList = [...this.oldquestionsList]
        }
      }
      // 第8题,情况2此时存在4、5
      if (index == 82 && this.sixFlag) {
        if (this.answer[6].answer == 'B') {
          this.questionsList = this.oldquestionsList.filter((item, index) => {
            if (index != 8 && index != 5) {
              return item
            }
          })
          this.nineFalg = true;
        } else {
          this.nineFalg = false;
          this.questionsList = this.oldquestionsList.filter((item, index) => {
            if (index != 5) {
              return item
            }
          })
        }
      }
    },
    // 下一题
    next() {
      // console.log(this.answer);
      // 当前任务ID
      var id = this.questionsList[this.index].id;

      // 判断LEC
      if (this.LessList.indexOf(this.measureInfo.measure_title) != -1 && this.inputQuesList.indexOf(this.index + 1) != -1 && this.inputVal == '') {
        this.$message({
          showClose: true,
          duration: 1500,
          message: "请输入答案后进入下一题",
          type: "warning",
        });
        return;
      }else if(this.LessList.indexOf(this.measureInfo.measure_title) != -1 && this.inputQuesList.indexOf(this.index + 1) != -1 && this.inputVal*1 < 1) {
        this.$message({
          showClose: true,
          duration: 1500,
          message: "输入值不能小于1",
          type: "warning",
        });
        return;
      }
      if(this.inputVal !== "" && !this.inputsList[`${this.index + 2}`]) {
        this.inputsList[`${this.index + 1}`] = this.inputVal;
        localStorage.setItem("inputsList",JSON.stringify(this.inputsList));
        this.inputVal = "";
      };

      if (this.LessList.indexOf(this.measureInfo.measure_title) != -1 && this.inputsList[`${this.index + 2}`]){
         this.inputVal =  this.inputsList[`${this.index + 2}`];
      }

      // 判断是否存在input框
      if(this.questionsList[this.index].question.includes('由评定者填写')){
        if(this.picked2 == '' && this.picked2List.indexOf(this.index) != -1) {
          this.$message.warning("请填写输入框后提交");
          return;
        }else if(this.picked3 == '' && this.picked3List.indexOf(this.index) != -1){
          this.$message.warning("请填写输入框后提交");
          return;
        }else if(this.picked4 == '' && this.picked4List.indexOf(this.index) != -1) {
          this.$message.warning("请填写输入框后提交");
          return;
        }; 
      };

      if (this.LessList.indexOf(this.measureInfo.measure_title) != -1 && this.inputQuesList.indexOf(this.index + 1) != -1) {
        
      }else if(this.picked === "") {
        // 判断能否进入下一题
          this.$message({
            showClose: true,
            duration: 1500,
            message: "选择后，才能进入下一题",
            type: "warning",
          });
          return;
      }

      // 提交新数据处理
      // arr：选项A-Z
      var arr = [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ];
      var answer = arr[this.picked]; //this.picked 当前选择选项序列号
      var obj = { id, answer }; //  当前题目答案包装
      // console.log(obj);
      // 判断是否已经添加过数据
      var tmp = this.answer.some((v) => {
        if (v.id == id) {
          return true;
        } else {
          return false;
        }
      });
      // 判断是更新还是新增;
      if (tmp) {
        // 更新
        this.answer[this.index] = obj;
      } else {
        // 新增
        this.answer.push(obj);
        localStorage.setItem(
          `measureId${this.uid}-${this.measureId}`,
          JSON.stringify(this.answer)
        );
        // 计算进度条
        this.Progress = Math.ceil(
          (this.answer.length / this.questionsList.length) * 100
        );
      }

      if (this.measureInfo.measure_title == "产前风险问卷（ANRQ）") {
        // 答题答到第三题
        if ((this.index + 1) == 3) {
          let arr = []
          this.answer.map((item, index) => {
            if (index == 1 || index == 2) {
              arr.push(item.answer)
            }
          });
          // 当2,3题为B的时候
          this.flag = arr.every(item => {
            return item == "B";
          });
          // flag为真，3、4、5题不存在；反之不管
          this.changeTheTopic(3, this.flag)
        }

        // 被删除了4、5、6题的时候;第8题再运行
        if (this.flag) {
          if ((this.index + 1) == 5) {
            // 答题答到第8题
            this.changeTheTopic(81)
          }
          // 4、5、6题还存在
        } else {
          // 判断第5题
          if ((this.index + 1) == 5) {
            this.changeTheTopic(5)
          }
          // 
          if (this.sixFlag && (this.index + 1) == 7) {
            this.changeTheTopic(82)
          }
          // 同时判断第8题
          if ((this.index + 1) == 8 && !this.sixFlag) {
            this.changeTheTopic(82)
          }
        }


      }
      // 判断是否完成
      if (this.index >= this.questionsList.length - 1) {
        this.$message({
          showClose: true,
          duration: 1500,
          message: "已经完成了所有题目,没有问题就可以点击提交了哦",
          type: "warning",
        });
        return;
      }
      // // 字体图标的变换
      var p = document.querySelectorAll(".fa");
      // // 1-还原所有
      p.forEach((v) => {
        v.classList.remove("fa-check-square");
        v.classList.add("fa-square-o");
      });
      var tr = document.querySelectorAll("tr");
      tr.forEach((v) => {
        v.classList.remove("active");
      });
      // --------------------------跳题-start------------------------------------
      // 1.判断是否跳题
      // 2.判断需要跳多少题
      // 3.把跳过的题添加为空的答案
      // 4.记录调后的题目.打上标记方便回跳
      // ++++++++++
      // this.questionsList[this.index])：跳题之前的题目
      // this.picked：选项下标
      let tiao = this.questionsList[this.index].jump_info[this.picked];
      //olIndex：开始添加空题的下标     isIndex：当前题目下标
      let olIndex = JSON.parse(JSON.stringify(this.index)) + 1;
      let isIndex = JSON.parse(JSON.stringify(this.index));
      if (tiao != undefined && tiao > this.index) {
        if (tiao > this.questionsList.length) {
          tiao = this.index + 1;
          this.isEnd = true;
          this.$message.success("当前可以提交");
          this.answer.splice(tiao);
        } else {
          this.isEnd = false;
        }

        // 跳题后的下标
        this.index = tiao - 1;
        // 处理已经跳过题的答案 olIndex +1  this.index
        for (olIndex; olIndex < this.index; olIndex++) {
          let obj = {
            id: this.questionsList[olIndex].id,
            answer: "",
          };
          if (tmp) {
            this.answer[olIndex] = obj;
          } else {
            this.answer.push(obj);
          }
        }
        localStorage.setItem(
          `measureId${this.uid}-${this.measureId}`,
          JSON.stringify(this.answer)
        );
        // 计算进度条
        this.Progress = Math.ceil(
          (this.answer.length / this.questionsList.length) * 100
        );
        // 记录跳题后的ID 用于 用于回溯
        let recordTmp = {
          id: this.questionsList[this.index].id,
          from: isIndex,
        };
        this.recordTiao.push(recordTmp);
        localStorage.setItem(
          `recordTiao${this.uid}-${this.measureId}`,
          JSON.stringify(this.recordTiao)
        );
      } else {
        // 页码+1
        this.index++;
      }

      // --------------------------跳题-end--------------------------------------

      // 清空
      this.picked = "";
      // 渲染已选择的题目
      this.$nextTick(() => {
        this.loadnext();
      });
    },
    // 上一题
    last() {
      if (this.index <= 0) {
        this.$message({
          showClose: true,
          duration: 1500,
          message: "已经是第一道题了",
          type: "warning",
        });
        return;
      }
      this.isEnd = false;
      // 查找跳题记录中是否存在
      let a = this.recordTiao.find((v) => {
        return v.id == this.questionsList[this.index].id;
      });
      if (a != undefined) {
        this.index = a.from;
      } else {
        this.index--;
      }
      this.$nextTick(() => {
        if (this.LessList.indexOf(this.measureInfo.measure_title) && this.inputQuesList.indexOf(this.index + 1) != -1){
         this.inputVal =  this.inputsList[`${this.index + 1}`]
        }else {
          this.loadnext();
        }
      });
    },
    // 渲染已选择的题目
    loadnext() {
      var id = this.questionsList[this.index].id;
      // 先判断是否有值，没有则 退出
      var tmp = this.answer.some((v) => {
        if (v.id == id) {
          return true;
        } else {
          return false;
        }
      });
      if (!tmp) {
        return;
      }
      // 清除样式
      var p = document.querySelectorAll(".fa");
      p.forEach((v) => {
        v.classList.remove("fa-check-square");
        v.classList.add("fa-square-o");
      });
      var tr = document.querySelectorAll("tr");
      tr.forEach((v) => {
        v.classList.remove("active");
      });
      //模拟已选择
      var arr = [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ];
      var answers = this.answer[this.index].answer;
      var index = arr.indexOf(answers);
      this.picked = index;
      document.querySelectorAll("tr")[index].classList.add("active");
      document.querySelectorAll(".fa")[index].classList.add("fa-check-square");
    },
    // 提交
    submit() {
      // 2022年10月31日 唐加宇  保存用户信息
      this.saveUser();

      if(this.answer.length < this.questionsList.length) {
        for (let i = this.answer.length; i < this.questionsList.length; i++) {
          let obj = {id: this.questionsList[i].id, answer: ''}
          this.answer.push(obj);
        }
      };
      

      console.log(this.answer,'this.answer--提交！');
      // return
      this.isSubmit = true;
      let obj = {
        measureId: this.measureId,
        systemAccess: {
          accessToken: "md5",
        },
        isSave: true,
        answer: this.answer,

      };

      obj.user_id = localStorage.getItem("user_id");
      // 产前量表做单独处理
      if (this.measureInfo.measure_title == '产前风险问卷（ANRQ）') {
        // console.log("量表数据处理",obj);
        // 1.当4,5,6题没有
        // console.log("111",this.flag );
        // console.log("6660",this.sixFlag );
        // console.log("9999",this.nineFalg );
        // console.log("999911",this.answer );
        // return

        if (this.flag && !this.nineFalg) {
          // console.log(111111111);
          this.oldquestionsList1.filter(item => {
            obj.answer.filter((item2 => {
              if (item2.id == item.id) {
                // console.log(item2);
                item.answer = item2.answer
              }
            }))
            this.answerarr.push(item)
          })
          this.answerarr[3].answer = 'f'
          this.answerarr[4].answer = 'b'
          this.answerarr[5].answer = 'd'
        }
        // 2.当4、5、6、9题都没有时候
        if (this.flag && this.nineFalg) {
          // console.log(222222222);
          this.oldquestionsList1.filter(item => {
            obj.answer.filter((item2 => {
              if (item2.id == item.id) {
                item.answer = item2.answer
              }
            }))
            this.answerarr.push(item)
          })
          this.answerarr[3].answer = 'f'
          this.answerarr[4].answer = 'b'
          this.answerarr[5].answer = 'd'
          this.answerarr[8].answer = 'f'
        }
        // 2.当6题没有的时候
        // console.log("6",this.sixFlag);
        // console.log("9",this.nineFalg);
        // return
        if (this.sixFlag && !this.nineFalg) {
          // console.log(333333333333,this.answerarr);
          this.oldquestionsList1.filter(item => {
            obj.answer.filter((item2 => {
              if (item2.id == item.id) {
                item.answer = item2.answer
              }
            }))
            this.answerarr.push(item)
          })
          this.answerarr[5].answer = 'd'
        }
        // 3.当6、9题没有的时候
        if (this.sixFlag && this.nineFalg) {
          // console.log(44444444);
          this.oldquestionsList1.filter(item => {
            obj.answer.filter((item2 => {
              if (item2.id == item.id) {
                item.answer = item2.answer
              }
            }))
            this.answerarr.push(item)
          })
          this.answerarr[5].answer = 'd'
          this.answerarr[8].answer = 'f'
        }

        if (!this.sixFlag && !this.nineFalg && !this.flag) {
          this.oldquestionsList1.filter(item => {
            obj.answer.filter((item2 => {
              if (item2.id == item.id) {
                item.answer = item2.answer
              }
            }))
            this.answerarr.push(item)
          })
        }
        obj.answer = this.answerarr
      }

	  const arr = this.questionsList.filter((item)=> this.setInput(item.question));
    // to do!!!!!
	  const inputs = arr.map((item)=> ({ id:item.id })) || [];
    // 前三个是picked2 ，中间三个是picked3 ，后面三个是picked4
    inputs.forEach((item,index)=>{
      if(index < 3) {
        item.answer = this.picked2
      }else if (index < 6) {
        item.answer = this.picked3
      }else if(index < 9) {
        item.answer = this.picked4
      }
    });

    let newObj = { ...obj, inputs }

    // 如果是紧张性生活事件评定量表 处理inputs
    if (this.LessList.indexOf(this.measureInfo.measure_title) != -1){
      newObj.inputs = this.inputsList;
      newObj.answer.forEach((item)=>{
          if(!item.answer) {
            item.answer = "";
          }
        });
    };

      submit(newObj)
        .then((res) => {
          let id = res.data.id;
          if (res.code == 400200) {
            this.$message({
              message: "恭喜你，提交成功",
              type: "success",
              showClose: true,
              duration: 1500,
            });

            localStorage.removeItem("inputsList");

            // 判断是否继续填写问卷
            if (this.continueMes) {
              let allMesId = JSON.parse(localStorage.getItem("allMesId"));
              allMesId = allMesId.filter((v) => {
                return v != this.$route.query.id;
              });
              if (allMesId.length == 0) {
                this.$router.push(`/answer_content/answerUserMeasure`);
              } else {
                this.$router.push(`/answer_content?id=${allMesId[0]}`);
                localStorage.setItem("allMesId", JSON.stringify(allMesId));
                this.reload();
              }
            } else {
              if (this.type === 1) {
                this.$router.push(`/answer_content/answerChart?id=${id}`);
              } else {
                this.$router.push(`/answer_content/answer_list`);
              }
            }
            this.isSubmit = false;
            localStorage.removeItem(`measureId${this.uid}-${this.measureId}`);
            localStorage.removeItem(`recordTiao${this.uid}-${this.measureId}`);
          } else {
            this.isSubmit = false;
          }
        })
        .catch((err) => {
          this.isSubmit = false;
        });
    },
    // 保存用户信息
    saveUser() {
      const userInfo = JSON.parse(localStorage.getItem("userInfo"))
      if (this.popupsParam == {} || !userInfo) {
        return null;
      }
      userInfo.archives = this.popupsParam
      saveInfo(userInfo).then((res) => {
        if (res.code === 400200) {
          this.$message({
            showClose: true,
            message: "保存成功！",
            type: "success",
          });
        }
      });
    },
  },
};
</script>
<style lang="less">
.tc-answer-content {
  height: 100%;

  .answer-content-layout {
    max-width: 1200px;
    min-width: 320px;
    margin: 0 auto;
    padding-bottom: 10px;

    .title {
      text-align: center;
      font-weight: 700;
      font-size: 28px;
      padding: 20px 0;
    }

    .msg {
      padding: 15px;
    }

    .describe {
      padding: 15px;
    }

    .answer-main {
      height: calc(~"100% - 300px");
      min-height: 500px;
      position: relative;
      box-sizing: border-box;
      width: 100%;
      // height: 100%;
      background: white;
      border-radius: 20px;
      padding: 20px;
      z-index: 1;

      .main-title {
        font-size: 25px;
        border-bottom: 1px #cccccc solid;
        color: rgb(9, 100, 204);
        padding-bottom: 10px;

        img {
          width: 180px;
        }
      }

      .centen {
        padding: 30px 0;
        width: 100%;

        table {
          width: 100%;

          tr {
            width: 100%;

            &:hover {
              background: paleturquoise;
            }

            td {
              padding: 10px 10px;
              width: 100%;

              input[type="radio"] {
                display: none;
              }

              .fa-check-square,
              .fa-square-o {
                font-size: 25px;
                color: rgb(9, 100, 204);
                vertical-align: middle;
              }

              i {
                width: 50px;
              }

              span {
                vertical-align: middle;
                font-size: 20px;
                color: rgb(9, 100, 204);
              }

              label {
                width: 100%;
                display: inline-block;
              }

              img {
                width: 55px;
                vertical-align: middle;
              }
            }
          }
        }
      }

      .active {
        background: paleturquoise;
      }
    }

    .footer {
      position: relative;
      z-index: 999;
      box-sizing: border-box;
      padding: 0 10%;
      width: 100%;
      height: 80px;
      border-radius: 0 0 20px 20px;
      box-shadow: -1px -12px 16px #9c9c9c;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: rgb(255, 255, 255);
      transform: translateY(-10px);

      .btn {
        .el-button {
          font-size: 28px;
        }
      }

      .sum {
        flex: 3;
        text-align: center;
        font-size: 20px;
        color: rgb(96, 98, 102);

        .sum_info {
          color: orange;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .tc-answer-content {

    // 加载等待框
    .el-loading-mask {
      .el-loading-spinner {
        .el-icon-loading {
          color: rgb(34, 77, 85);
        }

        .el-loading-text {
          color: rgb(34, 77, 85);
          font-weight: 700;
        }
      }
    }

    .answer-content-layout {
      max-width: 812px;
      min-width: 320px;
      margin: 0 auto;

      .title {
        text-align: center;
        font-weight: 700;
        font-size: 20px;
        padding: 10px 0;

        .el-progress.el-progress--line {
          padding: 0 20px;

          .el-progress-bar__outer {
            height: 3px !important;
          }
        }
      }

      .answer-main {
        height: calc(~"100% - 150px");
        min-height: calc(100vh - 215px);
        // overflow: auto;
        position: relative;
        box-sizing: border-box;
        width: 100%;
        background: white;
        border-radius: 20px;
        padding: 15px 20px;
        z-index: 1;

        .main-title {
          font-weight: 700;
          font-size: 18px;
          border-bottom: 1px #cccccc solid;
          color: rgb(9, 100, 204);
          padding-bottom: 10px;
        }

        .centen {
          padding: 30px 0;
          width: 100%;

          table {
            width: 100%;

            tr {
              width: 100%;

              &:hover {
                background: transparent;
              }

              &:active {
                background: paleturquoise;
              }

              td {
                padding: 10px 10px;
                width: 100%;

                input[type="radio"] {
                  display: none;
                }

                .fa-check-square,
                .fa-square-o {
                  font-size: 20px;
                  color: rgb(9, 100, 204);
                  vertical-align: middle;
                }

                i {
                  width: 30px;
                }

                span {
                  font-size: 16px;
                  color: rgb(9, 100, 204);
                  vertical-align: middle;
                }

                label {
                  width: 100%;
                  display: inline-block;
                }
              }
            }
          }
        }

        .active {
          // background: paleturquoise;
        }
      }

      .footer {
        position: relative;
        z-index: 999;
        box-sizing: border-box;
        padding: 0 3%;
        width: 100%;
        height: 80px;
        border-radius: 0 0 20px 20px;
        box-shadow: -1px -12px 16px #9c9c9c;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: rgb(255, 255, 255);
        transform: translateY(-10px);

        .btn {
          .el-button {
            font-size: 16px;
          }
        }

        .sum {
          flex: 3;
          text-align: center;
          font-size: 14px;
          color: rgb(96, 98, 102);

          .sum_info {
            color: orange;
          }
        }
      }
    }
  }
}

// 瑞文标准推理测验
.tc-answer-content .answer-content-layout .answer-main .main-title img {
  width: 250px;
} 

.tc-answer-content .answer-content-layout .answer-main .centen table tr td img {
  width: 80px;
}
</style>